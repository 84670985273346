import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { ActivitySlug } from '../../../../types/Activity.js';
import { AddSessionAPIRes } from '../../../../types/Session.js';
import { WritingScaffolds } from '../../../../types/WritingScaffolds.js';

export const useAddSession = () => {
    const addSession = useMutation(
        ({
            questionId,
            activitySlug,
            joinCode,
            allowCopyPaste,
            allowOpenComments,
            enableImmersiveReader,
            writingScaffolds,
            allInMaxPairingsCount,
        }: {
            questionId: string;
            activitySlug: ActivitySlug;
            joinCode: string;
            allowCopyPaste: boolean;
            allowOpenComments: boolean;
            enableImmersiveReader: boolean;
            writingScaffolds: WritingScaffolds;
            allInMaxPairingsCount: number;
        }): Promise<AddSessionAPIRes> => {
            return API.post(apiName, `/session/add`, {
                body: {
                    questionId,
                    activitySlug,
                    joinCode,
                    allowCopyPaste,
                    allowOpenComments,
                    writingScaffolds,
                    allInMaxPairingsCount,
                    enableImmersiveReader,
                },
            });
        },
    );

    return addSession;
};
