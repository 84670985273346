import { useMutation } from 'react-query';
import { API } from 'aws-amplify';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { apiName } from '../../../lib/amplify.js';

const useAddRoom = () => {
    const addRoom = useMutation(QueryKeys.addRoom, async () => {
        return API.post(apiName, '/room/add', {});
    });

    return addRoom;
};

export default useAddRoom;
