import { useEffect, useState, useCallback } from 'react';

export const useScrollShadow = (containerRef: React.RefObject<HTMLDivElement>) => {
    const [topShadow, setTopShadow] = useState(false);
    const [bottomShadow, setBottomShadow] = useState(false);
    const [leftShadow, setLeftShadow] = useState(false);
    const [rightShadow, setRightShadow] = useState(false);

    const calculateShadows = useCallback(() => {
        const container = containerRef.current;
        if (!container) return;

        const {
            scrollTop,
            scrollHeight,
            clientHeight,
            scrollWidth,
            scrollLeft,
            offsetWidth,
        } = container;

        // Show top shadow when there's content to scroll up to
        setTopShadow(scrollTop > 0);

        // Show bottom shadow when there's content to scroll down to
        setBottomShadow(Math.ceil(scrollTop + clientHeight) < scrollHeight);

        // Show left shadow when there's content to scroll left to
        setLeftShadow(scrollLeft > 0);

        // Show right shadow when there's content to scroll right to
        setRightShadow(Math.ceil(scrollLeft + offsetWidth) < scrollWidth);
    }, [containerRef]);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        // Calculate initial state
        calculateShadows();

        // Add scroll event listener
        container.addEventListener('scroll', calculateShadows);

        // Add resize event listener
        window.addEventListener('resize', calculateShadows);

        // Create MutationObserver to watch for content changes
        const observer = new MutationObserver(calculateShadows);
        observer.observe(container, {
            childList: true,
            subtree: true,
            characterData: true,
        });

        // Cleanup
        return () => {
            container.removeEventListener('scroll', calculateShadows);
            window.removeEventListener('resize', calculateShadows);
            observer.disconnect();
        };
    }, [calculateShadows]);

    // Recalculate when the ref changes
    useEffect(() => {
        calculateShadows();
    }, [containerRef.current, calculateShadows]);

    return {
        topShadow,
        bottomShadow,
        leftShadow,
        rightShadow,
    };
};
