import React, { createContext, useState, useCallback, useEffect } from 'react';
import { Room } from '../../../types/Room.js';
import { useRoom } from '../hooks/queries/useRoom.js';
import { useStripeSubscriptionStatus } from '../hooks/mutations/useStripeSubscriptionStatus.js';
import useAddRoom from '../hooks/mutations/useAddRoom.js';

export const RoomContext = createContext<Room>(null);
export const SetRoomContext = createContext<(room: Room) => void>(null);

export const RoomProvider = ({ children }: { children: JSX.Element }) => {
    const [room, setRoom] = useState<Room>(null);

    const getStripeSubscriptionStatus = useStripeSubscriptionStatus();
    const addRoomMutation = useAddRoom();

    const onSuccess = (response: { room: Room }) => {
        setRoom(response.room);
    };

    useRoom(onSuccess);

    const handleSetRoomContext = useCallback(
        (newRoom: Room) => {
            setRoom(newRoom);
        },
        [room?.id],
    );

    useEffect(() => {
        if (room) {
            getStripeSubscriptionStatus.mutate();
        }

        if (!room) {
            addRoomMutation.mutate(undefined, {
                onSuccess: (response) => {
                    setRoom(response.room);
                },
            });
        }
    }, [room?.id]);

    return (
        <RoomContext.Provider value={room}>
            <SetRoomContext.Provider value={handleSetRoomContext}>
                {children}
            </SetRoomContext.Provider>
        </RoomContext.Provider>
    );
};
