import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Participant } from '../../../../types/Participant.js';

export const useParticipant = ({ participantId }: { participantId: string }) => {
    const getComments = useQuery<{
        participant: Participant;
    }>(
        QueryKeys.participantComments,
        async () => {
            const url = new URL(window.location.href);

            const response = await fetch(
                `${url.origin}/api/participant/${participantId}`,
            );

            if (!response.ok) {
                throw new Error('Error fetching participant.');
            }

            return response.json();
        },
        { enabled: !!participantId, cacheTime: 0 },
    );

    return getComments;
};
