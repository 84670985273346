import { Storage } from 'aws-amplify';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Attachment } from '../../../../types/Attachment.js';

interface AttachmentParticipantId extends Attachment {
    participant_id?: string;
}

export const useFilesFromAWS = (
    id: string,
    attachments: Partial<AttachmentParticipantId>[],
) => {
    return useQuery(
        [QueryKeys.filesFromAWS, id],
        async () => {
            const filePromises = attachments?.map(async (attachment) => {
                // If the attachment is public, we save the file as participantId/fileName to avoid file name collisions
                const storageFileName =
                    attachment?.access_level === 'public'
                        ? `${attachment.participant_id}/${attachment.file_name}`
                        : attachment?.file_name;

                const file = await Storage.get(storageFileName, {
                    level: attachment?.access_level,
                    download: true,
                    identityId: attachment?.aws_identity,
                    cacheControl: 'max-age=3600',
                });

                // @ts-ignore
                return new File([file.Body as Blob], attachment.file_name, {
                    type: attachment.file_type,
                });
            });

            const files = await Promise.all(filePromises);

            const dataTransfer = new DataTransfer();

            files.forEach((file) => {
                dataTransfer.items.add(file);
            });

            return dataTransfer.files;
        },
        {
            enabled: !!(attachments?.length > 0),
            cacheTime: 0,
            refetchOnWindowFocus: false,
        },
    );
};
