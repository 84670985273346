import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { apiName } from '../../../lib/amplify.js';

export const useAddTopic = () => {
    const addTopic = useMutation(async ({ name }: { name: string }) => {
        return API.post(apiName, '/topic/add', {
            body: {
                name,
            },
        });
    });

    return addTopic;
};
