import { useQuery } from 'react-query';
import { Storage } from 'aws-amplify';
import { captureException } from '@sentry/react';

export const useProfileImage = () => {
    return useQuery(
        'profile-image',
        async () => {
            try {
                const profileImage = await Storage.get('profile-image', {
                    level: 'private',
                    download: true,
                });

                // @ts-ignore
                const profileImageBlob = profileImage.Body as Blob;

                return profileImageBlob;
            } catch (error) {
                if (
                    error.message === 'NoSuchKey' ||
                    error.message === 'The specified key does not exist.'
                ) {
                    return null;
                }

                captureException(error);
            }
        },
        {
            refetchOnWindowFocus: false,
        },
    );
};
