import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { captureException } from '@sentry/react';
import { apiName } from '../../../lib/amplify.js';

export const useCreateStripeCustomerPortalSession = () => {
    const createStripCustomerPortalSession = useMutation(
        async () => {
            return API.post(apiName, '/stripe/create-portal-session', {});
        },
        {
            onSuccess: (data) => {
                const stripeUrl = data?.sessionUrl;

                if (stripeUrl) {
                    // redirect to stripe portal
                    window.open(stripeUrl, '_blank');
                }
            },
            onError: (error) => {
                captureException(error);
            },
        },
    );

    return createStripCustomerPortalSession;
};
