import { API, Auth } from 'aws-amplify';
import { captureException } from '@sentry/react';
import { useMutation } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { Session } from '../../../../types/Session.js';
import { Question } from '../../../../types/Question.js';
import { SharedQuestion } from '../../../../types/SharedQuestion.js';
import { copyProtectedFilesInS3 } from '../../_helpers/copyProtectedFilesInS3.js';

/**
 * This hook hits the /sharedQuestion/save endpoint to convert shared questions to questions in the target users room
 */
export const useSaveSharedQuestions = () => {
    const saveSharedQuestions = useMutation(
        async ({
            sharedQuestions,
            targetRoomId,
            topicName,
            sharedTopicId,
        }: {
            sharedQuestions: SharedQuestion[];
            targetRoomId: string;
            topicName: string;
            sharedTopicId: string;
        }): Promise<{ session: Session; question: Question }> => {
            const { identityId } = await Auth.currentCredentials();

            try {
                const copySharedAttachments = sharedQuestions.map(
                    async (sharedQuestion) => {
                        await copyProtectedFilesInS3(
                            sharedQuestion.attachments,
                            identityId,
                        );
                    },
                );

                await Promise.all(copySharedAttachments);
            } catch (error) {
                captureException(error);
                throw new Error('Error uploading files to S3');
            }

            return API.post(apiName, `/sharedQuestion/save`, {
                body: {
                    sharedQuestions,
                    targetRoomId,
                    topicName,
                    sharedTopicId,
                    identityId,
                },
            });
        },
    );

    return saveSharedQuestions;
};
