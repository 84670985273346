import { useMutation, useQueryClient } from 'react-query';
import { captureException } from '@sentry/react';
import { API, Auth } from 'aws-amplify';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { apiName } from '../../../lib/amplify.js';

export const useStripeSubscriptionStatus = () => {
    const queryClient = useQueryClient();

    const getStripeSubscriptionStatus = useMutation(
        QueryKeys.stripeSubscriptionStatus,
        async () => {
            const currentUser = await Auth.currentAuthenticatedUser();
            const { email } = currentUser.attributes ?? {};

            return API.post(apiName, '/stripe/subscription-status', {
                body: {
                    email,
                },
            });
        },
        {
            onError: (error) => {
                captureException(error);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(QueryKeys.room);
            },
        },
    );

    return getStripeSubscriptionStatus;
};
