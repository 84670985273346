import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Topic } from '../../../../types/Topic.js';

export const useAllRoomTopics = () => {
    const getAllRoomTopics = useQuery<{ topics: Topic[] }>(
        QueryKeys.allRoomTopics,
        async () => {
            return API.get(apiName, `/topic/all/`, {});
        },
        {
            cacheTime: 0,
        },
    );

    return getAllRoomTopics;
};
