import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { QueryKeys } from '../../../../constants/queryKeys.js';

/**
 * Get remaining trial days for stripe subscription
 */
export const useRemainingTrialDays = () => {
    const getRemainingTrialDays = useQuery<{
        remainingTrialDays: number;
        totalTrialDays: number;
    }>(
        QueryKeys.stripeRemainingTrialDays,
        async () => {
            return API.get(apiName, `/stripe/trial-days`, {});
        },
        {
            refetchOnWindowFocus: false,
        },
    );

    return getRemainingTrialDays;
};
