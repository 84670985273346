import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Question } from '../../../../types/Question.js';

export const useAllQuestions = () => {
    const getAllQuestions = useQuery<{ questions: Question[] }>(
        QueryKeys.allRoomQuestions,
        async () => {
            return API.get(apiName, '/question/all', {});
        },
    );

    return getAllQuestions;
};
