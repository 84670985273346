import { useContext } from 'react';
import { useMutation } from 'react-query';
import { captureException } from '@sentry/react';
import { AI_FEEDBACK } from '../../../../constants/channels.js';
import { SocketContext } from '../../context/Socket.js';
import { SocketResponse } from '../../../../types/Socket.js';

export const useAIProficiencyFeedbackForResponse = (joinCode: string) => {
    const socket = useContext(SocketContext);

    const addAIProciencyLevelFeedback = useMutation(
        async ({
            sessionId,
            responseId,
        }: {
            sessionId: string;
            responseId: string;
        }): Promise<{
            aiFeedback: Array<{
                criteria_id: string;
                criteria: string;
                ai_feedback: string;
                proficiency_level: string;
            }>;
        }> => {
            const url = new URL(window.location.href);

            const response = await fetch(
                `${url.origin}/api/bedrock/proficiency/feedback`,
                {
                    method: 'POST',
                    mode: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        sessionId,
                        responseId,
                    }),
                },
            );

            if (!response.ok) {
                const errorBody = await response.json();

                captureException(errorBody.error);

                throw new Error(errorBody.error);
            }

            return response.json();
        },
        {
            onError: (error) => {
                captureException(error);
            },
            onSuccess: () => {
                // Handle success if needed
                socket.emit(
                    AI_FEEDBACK.GENERATED,
                    {
                        joinCode,
                    },
                    (res: SocketResponse) => {
                        if (!res.success) {
                            captureException(
                                new Error('Failed to emit AI_FEEDBACK.GENERATED'),
                            );
                        }
                    },
                );
            },
        },
    );

    return addAIProciencyLevelFeedback;
};
