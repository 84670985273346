import { captureException } from '@sentry/react';
import React, { createContext, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';

export const SocketContext = createContext<Socket>(null);

/**
 * We need this logic to connect to the socket in development with webpack-dev-server
 */
const url = window.location.href;
let socketUrl = window.location.origin;

if (url.includes('localhost')) {
    socketUrl = `http://localhost:${process.env.PORT || 3000}`;
} else if (process.env.NODE_ENV === 'development') {
    socketUrl = `http://${process.env.IP}:8080`;
}

const socketInstance = io(socketUrl, {
    withCredentials: true,
});

export const SocketProvider = ({ children }: { children: JSX.Element }) => {
    useEffect(() => {
        socketInstance.on('disconnect', (reason) => {
            captureException(`Socket disconnected: ${reason}`);
        });

        socketInstance.on('connect_error', (error) => {
            captureException(`error connecting to socket: ${error}`);
        });

        // Get the current transport being used
        console.log('Current transport:', socketInstance.io.engine.transport.name);

        return () => {
            socketInstance.off('disconnect');
            socketInstance.off('connect_error');
        };
    }, []);

    return (
        <SocketContext.Provider value={socketInstance}>{children}</SocketContext.Provider>
    );
};
