import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Question } from '../../../../types/Question.js';

export const useSingleQuestion = (questionId: string) => {
    const getSingleQuestionById = useQuery<{
        question: Question;
    }>(
        QueryKeys.singleQuestion,
        async () => {
            const url = new URL(window.location.href);

            const response = await fetch(`${url.origin}/api/question/${questionId}`);

            if (!response.ok) {
                throw new Error('Error getting single question');
            }

            return response.json();
        },
        { enabled: !!questionId, cacheTime: 0 },
    );

    return getSingleQuestionById;
};
