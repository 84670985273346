import { API, Auth } from 'aws-amplify';
import { useMutation } from 'react-query';
import { Question } from '../../../../types/Question.js';
import { apiName } from '../../../lib/amplify.js';
import { convertFileListToAttachmentDetails } from '../../_helpers/convertFileListToAttachmentDetails.js';
import { uploadProtectedFilesToS3 } from '../../_helpers/uploadProtectedFilesToS3.js';
import { Attachment } from '../../../../types/Attachment.js';

export interface EditQuestionParams {
    question: Partial<Question>;
    filesToUpload: FileList | {};
}

export const useEditQuestion = () => {
    const editQuestion = useMutation(
        async ({ question, filesToUpload }: EditQuestionParams) => {
            const { identityId } = await Auth.currentUserCredentials();

            const fileDetails = convertFileListToAttachmentDetails(
                filesToUpload,
                'protected',
                identityId,
            );

            const response = await API.post(
                apiName,
                '/attachment/update-question-attachments',
                {
                    body: {
                        questionId: question.id,
                        fileDetails,
                        identityId,
                    },
                },
            );

            const {
                filesToAdd,
            }: { filesToDelete: Attachment[]; filesToAdd: Attachment[] } = response;

            if (filesToAdd.length > 0) {
                await uploadProtectedFilesToS3(filesToUpload);
            }

            return API.post(apiName, '/question/edit', {
                body: {
                    question,
                },
            });
        },
    );

    return editQuestion;
};
