import { useMutation } from 'react-query';
import { WritingScaffolds } from '../../../../types/WritingScaffolds.js';

export const useGenerateWritingScaffold = () => {
    const generateWritingScaffold = useMutation<
        {
            text: string;
        },
        { error: string },
        { question: string; scaffold: keyof WritingScaffolds }
    >(
        async ({
            question,
            scaffold,
        }: {
            question: string;
            scaffold: keyof WritingScaffolds;
        }) => {
            const url = new URL(window.location.href);

            const response = await fetch(`${url.origin}/api/bedrock/${scaffold}`, {
                method: 'POST',
                mode: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ question }),
            });

            if (!response.ok) {
                throw new Error('Error generating writing scaffold.');
            }

            return response.json();
        },
    );

    return generateWritingScaffold;
};
