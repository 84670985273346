import { useMutation } from 'react-query';
import { API } from 'aws-amplify';
import { apiName } from '../../../lib/amplify.js';

const useDeleteTopic = () => {
    const deleteTopic = useMutation(({ id, roomId }: { id: string; roomId: string }) => {
        return API.del(apiName, `/topic/delete/${roomId}/${id}`, null);
    });

    return deleteTopic;
};

export default useDeleteTopic;
