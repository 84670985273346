import { useMutation } from 'react-query';
import { Participant } from '../../../../types/Participant.js';

export const useUpdateParticipant = () => {
    const updateParticipant = useMutation(
        async ({ updatedParticipant }: { updatedParticipant: Participant }) => {
            const url = new URL(window.location.href);

            const response = await fetch(`${url.origin}/api/participant/update`, {
                method: 'PUT',
                mode: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ participant: updatedParticipant }),
            });

            if (!response.ok) {
                throw new Error('Error updating participant.');
            }

            return response.json();
        },
    );

    return updateParticipant;
};
