import { useFilesFromAWS } from './useFilesFromAWS.js';
import { Attachment } from '../../../../types/Attachment.js';

// This hook is used to get the files attached to a question at the time a session was created.
// This is necessary for reports because users see a "snapshot" of the question as it
// was when the activity was launched
export const useSessionQuestionFileAttachments = ({
    questionId,
    attachments,
}: {
    questionId: string;
    attachments: Attachment[];
}) => {
    const { data: attachedFiles, isLoading: loadingFiles } = useFilesFromAWS(
        questionId,
        attachments,
    );

    const isLoading = loadingFiles;

    return {
        isLoading,
        attachedFiles,
        fileCount: attachments?.length,
    };
};
