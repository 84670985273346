import React, { createContext, useState, useCallback } from 'react';
import { FeatureFlag } from '../../../types/FeatureFlag.js';
import { useFeatureFlags } from '../hooks/queries/useFeatureFlags.js';

export const FeatureFlagContext = createContext<FeatureFlag[]>(null);
export const SetFeatureFlagContext =
    createContext<(featureFlag: FeatureFlag) => void>(null);

export const FeatureFlagProvider = ({ children }: { children: JSX.Element }) => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

    const onSuccess = (response: FeatureFlag[]) => {
        setFeatureFlags(response);
    };

    useFeatureFlags(onSuccess);

    const handleSetFeatureFlagContext = useCallback(
        (newFeatureFlag: FeatureFlag) => {
            setFeatureFlags([...featureFlags, newFeatureFlag]);
        },
        [featureFlags?.length],
    );

    return (
        <FeatureFlagContext.Provider value={featureFlags}>
            <SetFeatureFlagContext.Provider value={handleSetFeatureFlagContext}>
                {children}
            </SetFeatureFlagContext.Provider>
        </FeatureFlagContext.Provider>
    );
};
