import { NavigateFunction } from 'react-router-dom';
import { ActivityRound } from '../../../../types/Room.js';

export const allInRoundMonitor = ({
    joinCode,
    currentSessionActivityRound,
    navigate,
}: {
    joinCode: string;
    currentSessionActivityRound: ActivityRound;
    navigate: NavigateFunction;
}) => {
    switch (currentSessionActivityRound) {
        case 'question_round':
            navigate(`/room/${joinCode}/participant/avatar-builder`, {
                replace: true,
            });
            break;

        case 'feedback_round':
            navigate(`/room/${joinCode}/all-in/participant/compare-responses`, {
                replace: true,
            });
            break;

        case 'discussion_round':
            navigate(`/room/${joinCode}/all-in/participant/feedback-report`, {
                replace: true,
            });

            break;

        // no default
    }
};
