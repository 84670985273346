import { useMutation } from 'react-query';
import { Auth } from 'aws-amplify';
import { captureException } from '@sentry/react';
import { uploadPublicFilesToS3 } from '../../_helpers/uploadPublicFilesToS3.js';
import { convertFileListToAttachmentDetails } from '../../_helpers/convertFileListToAttachmentDetails.js';
import { WritingScaffolds } from '../../../../types/WritingScaffolds.js';
import { AddUpdateResponseAPIRes } from '../../../../types/Response.js';

export const useAddResponse = () => {
    const addResponse = useMutation(
        async ({
            sessionId,
            responseText,
            participantId,
            confidenceScore,
            filesToUpload,
            writingScaffolds,
        }: {
            sessionId: string;
            responseText: string;
            participantId: string;
            confidenceScore: number;
            filesToUpload?: FileList;
            writingScaffolds: WritingScaffolds;
        }): Promise<AddUpdateResponseAPIRes> => {
            const url = new URL(window.location.href);

            let fileDetails = [];

            if (filesToUpload) {
                await uploadPublicFilesToS3(filesToUpload, participantId, sessionId);

                const { identityId } = await Auth.currentCredentials();

                fileDetails = convertFileListToAttachmentDetails(
                    filesToUpload,
                    'public',
                    identityId,
                );
            }

            const response = await fetch(`${url.origin}/api/response/add`, {
                method: 'POST',
                mode: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sessionId,
                    responseText,
                    participantId,
                    confidenceScore,
                    fileDetails,
                    writingScaffolds,
                }),
            });

            if (!response.ok) {
                const errorBody = await response.json();

                captureException(errorBody.error);

                throw new Error(errorBody.error);
            }

            return response.json();
        },
    );

    return addResponse;
};
