import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Attachment } from '../../../../types/Attachment.js';
import { Question } from '../../../../types/Question.js';

// This hook is used to get the attachment data from the db. Necessary for the useFilesFromAWS hook
export const useQuestionAttachments = (question: Question) => {
    return useQuery<{ attachments: Attachment[] }>(
        [QueryKeys.allQuestionAttachments, question?.id],
        async () => {
            const url = new URL(window.location.href);

            const response = await fetch(
                `${url.origin}/api/attachment/all/${question?.id}`,
            );

            return response.json();
        },
        {
            enabled: !!question?.id,
        },
    );
};
