import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { FeatureFlag } from '../../../../types/FeatureFlag.js';

export const useFeatureFlags = (onSuccess: (featureFlags: FeatureFlag[]) => void) => {
    const getFeatureFlags = useQuery<{ featureFlags: FeatureFlag[] }>(
        QueryKeys.featureFlags,
        async () => {
            return API.get(apiName, `/admin/feature-flags/`, {});
        },
        {
            onSuccess: (response) => {
                onSuccess(response?.featureFlags);
            },
        },
    );

    return getFeatureFlags;
};
