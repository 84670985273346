import { useMutation } from 'react-query';

export const useTranslateText = () => {
    const translateText = useMutation(
        async ({
            text,
            sourceLanguageCode,
            targetLanguageCode,
        }: {
            text: string;
            sourceLanguageCode: string;
            targetLanguageCode: string;
        }) => {
            const url = new URL(window.location.href);

            const response = await fetch(`${url.origin}/api/translate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    text,
                    sourceLanguageCode,
                    targetLanguageCode,
                }),
            });

            if (!response.ok) {
                throw new Error('Error translating text.');
            }

            return response.json();
        },
    );

    return translateText;
};
