import { useMutation } from 'react-query';
import { Session } from '../../../../types/Session.js';

export const useAddParticipant = () => {
    const addParticipant = useMutation(
        async ({ session, name }: { session: Session; name: string }) => {
            const url = new URL(window.location.href);

            const response = await fetch(`${url.origin}/api/participant/add`, {
                method: 'POST',
                mode: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ session, name }),
            });

            if (!response.ok) {
                if (response.status === 422) {
                    const errorBody = await response.json();
                    throw new Error(errorBody.error);
                }

                throw new Error('Error adding participant.');
            }

            return response.json();
        },
    );

    return addParticipant;
};
