import { API, Auth } from 'aws-amplify';
import { useMutation } from 'react-query';
import { captureException } from '@sentry/react';
import { Question } from '../../../../types/Question.js';
import { apiName } from '../../../lib/amplify.js';
import { uploadProtectedFilesToS3 } from '../../_helpers/uploadProtectedFilesToS3.js';
import { convertFileListToAttachmentDetails } from '../../_helpers/convertFileListToAttachmentDetails.js';

export interface AddQuestionParams {
    question: Pick<
        Question,
        'question' | 'feedback_opts' | 'writing_scaffolds' | 'topic_id'
    >;
    filesToUpload?: FileList;
}

export const useAddQuestion = () => {
    const addQuestion = useMutation(
        async ({ question, filesToUpload }: AddQuestionParams) => {
            let fileDetails = [];
            const { identityId } = await Auth.currentUserCredentials();

            if (filesToUpload) {
                try {
                    await uploadProtectedFilesToS3(filesToUpload);
                } catch (error) {
                    captureException(error);
                    throw new Error('Error uploading files to S3');
                }

                fileDetails = convertFileListToAttachmentDetails(
                    filesToUpload,
                    'protected',
                    identityId,
                );
            }

            return API.post(apiName, '/question/add', {
                body: {
                    question,
                    fileDetails,
                },
            });
        },
    );

    return addQuestion;
};
