import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { SharedQuestion } from '../../../../types/SharedQuestion.js';

/**
 * Currently shared questions are a group of topic questions sent from a source user to one or more target users.
 * These questions are completely decoupled from the source.
 * This hook is used to get all questions shared for a target user via roomId.
 */
export const useSharedQuestions = (roomId: string) => {
    const getSingleQuestionById = useQuery<{
        sharedQuestions: SharedQuestion[];
    }>(
        QueryKeys.sharedQuestions,
        async () => {
            const url = new URL(window.location.href);

            const response = await fetch(`${url.origin}/api/sharedQuestion/${roomId}`);

            if (!response.ok) {
                throw new Error('Error getting single question');
            }

            return response.json();
        },
        { enabled: !!roomId },
    );

    return getSingleQuestionById;
};
