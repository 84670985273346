import { NavigateFunction } from 'react-router-dom';
import { ActivityRound } from '../../../../types/Room.js';

export const quickWriteRoundMonitor = ({
    joinCode,
    currentSessionActivityRound,
    navigate,
}: {
    joinCode: string;
    currentSessionActivityRound: ActivityRound;
    navigate: NavigateFunction;
}) => {
    switch (currentSessionActivityRound) {
        case 'feedback_round':
            navigate(`/room/${joinCode}/quick-write/participant/feedback`, {
                replace: true,
            });
            break;

        case 'results_round':
            navigate(`/room/${joinCode}/quick-write/participant/results`, {
                replace: true,
            });
            break;

        case 'discussion_round':
            navigate(`/room/${joinCode}/quick-write/participant/review`, {
                replace: true,
            });
            break;

        // no default
    }
};
