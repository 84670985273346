import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { apiName } from '../../../lib/amplify.js';

/** Use update topic mutation */

export const useUpdateTopic = () => {
    const updateTopic = useMutation(({ name, id }: { name: string; id: string }) => {
        return API.put(apiName, `/topic/update/${id}`, {
            body: {
                name,
            },
        });
    });

    return updateTopic;
};
