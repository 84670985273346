import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Activity } from '../../../../types/Activity.js';

/** Use all activities query */

export const useAllActivities = () => {
    const getAllActivities = useQuery<{ activities: Activity[] }>(
        QueryKeys.allActivities,
        async () => {
            return API.get(apiName, `/activity/all/`, {});
        },
    );

    return getAllActivities;
};
