import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { SocketContext } from '../../context/Socket.js';
import { Participant } from '../../../../types/Participant.js';
import { PARTICIPANT } from '../../../../constants/channels.js';
import { SetParticipantContext } from '../../context/Participant.js';

/**
 * @description assign single participant to battle royale team. Used if participant is joining late.
 */
export const useAssignSingleBattleRoyaleTeam = () => {
    const socket = useContext(SocketContext);
    const setParticipant = useContext(SetParticipantContext);

    const { joinCode } = useParams();

    const assignBattleRoyalTeams = useMutation(
        async ({
            participant,
            sessionId,
        }: {
            participant: Participant;
            sessionId: string;
        }) => {
            const url = new URL(window.location.href);
            const response = await fetch(
                `${url.origin}/api/activity/battle-royale/participant/assign-team`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ participant, sessionId }),
                },
            );

            if (!response.ok) {
                captureException(await response.json());
                throw new Error('Error assigning participant to battle royale team');
            }

            socket.emit(PARTICIPANT.BR_TEAM_UPDATED, {
                joinCode,
                sessionId,
            });

            const body = await response.json();
            setParticipant(body.participant);

            return body;
        },
    );

    return assignBattleRoyalTeams;
};
