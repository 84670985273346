import { NavigateFunction } from 'react-router-dom';
import { ActivityRound } from '../../../../types/Room.js';

export const battleRoyaleRoundMonitor = ({
    joinCode,
    currentSessionActivityRound,
    navigate,
}: {
    joinCode;
    currentSessionActivityRound: ActivityRound;
    navigate: NavigateFunction;
}) => {
    switch (currentSessionActivityRound) {
        case 'question_round':
            navigate(`/room/${joinCode}/participant/avatar-builder`, {
                replace: true,
            });
            break;

        case 'feedback_round_1':
            /**
             * Knockout Round
             */
            navigate(`/room/${joinCode}/battle-royale/participant/knockout-round`, {
                replace: true,
            });
            break;

        case 'feedback_round_2':
            /**
             * Final Four Round
             */
            navigate(`/room/${joinCode}/battle-royale/participant/final-four`, {
                replace: true,
            });
            break;

        case 'feedback_round_3':
            /**
             * Championship Round
             */
            navigate(`/room/${joinCode}/battle-royale/participant/championship`, {
                replace: true,
            });
            break;

        case 'discussion_round':
            /**
             * Discussion Round/ Reflection
             */
            navigate(`/room/${joinCode}/battle-royale/participant/reflection`, {
                replace: true,
            });
            break;

        // no default
    }
};
