import { useMutation } from 'react-query';
import { API } from 'aws-amplify';
import { apiName } from '../../../lib/amplify.js';

const useDeleteRoom = () => {
    const deleteRoom = useMutation(() => {
        return API.post(apiName, '/room/delete', {});
    });

    return deleteRoom;
};

export default useDeleteRoom;
