import { useQuestionAttachments } from './useQuestionAttachments.js';
import { useFilesFromAWS } from './useFilesFromAWS.js';
import { Question } from '../../../../types/Question.js';

// This hook is used to get the AWS files attached to a question
export const useQuestionFileAttachments = ({ question }: { question: Question }) => {
    const { data: attachmentData, isLoading: loadingAttachments } =
        useQuestionAttachments(question);

    const { attachments } = attachmentData ?? {};

    const { data: attachedFiles, isLoading: loadingFiles } = useFilesFromAWS(
        question?.id,
        attachments,
    );

    const isLoading = loadingAttachments || loadingFiles;

    return {
        isLoading,
        attachedFiles,
        fileCount: attachments?.length,
    };
};
