import { useMutation } from 'react-query';
import { API } from 'aws-amplify';
import { apiName } from '../../../lib/amplify.js';

const useDeleteSharedTopicTargetRoom = () => {
    const deleteSharedTopicTargetRoom = useMutation(
        ({ targetRoomId, sharedTopicId }: { targetRoomId: string; sharedTopicId }) => {
            return API.del(
                apiName,
                `/sharedQuestion/delete/${sharedTopicId}/${targetRoomId}`,
                {},
            );
        },
    );

    return deleteSharedTopicTargetRoom;
};

export default useDeleteSharedTopicTargetRoom;
