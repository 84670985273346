import { useMutation } from 'react-query';

export const useDeleteMailchimpContact = () => {
    const deleteMailchimpContact = useMutation(async ({ email }: { email: string }) => {
        const url = new URL(window.location.href);

        const response = await fetch(`${url.origin}/api/mailchimp/${email}`, {
            method: 'DELETE',
        });

        return response.json();
    });

    return deleteMailchimpContact;
};
