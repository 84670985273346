import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { apiName } from '../../../lib/amplify.js';

export const useDeleteQuestion = () => {
    const deleteQuestion = useMutation(
        ({ questionId, roomId }: { questionId: string; roomId: string }) => {
            return API.del(apiName, `/question/${questionId}/${roomId}`, null);
        },
    );

    return deleteQuestion;
};
