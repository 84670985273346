import { useContext } from 'react';
import { SocketContext } from '../context/Socket.js';
import { SESSION } from '../../../constants/channels.js';
import { SocketResponse } from '../../../types/Socket.js';
import { SetSessionContext } from '../context/Session.js';
import { Session } from '../../../types/Session.js';

const useJoinSession = () => {
    const socket = useContext(SocketContext);
    const setSession = useContext(SetSessionContext);

    const joinSession = (
        { joinCode, participantId }: { joinCode: string; participantId?: string },
        onSuccess?: (session: Session) => void,
        onError?: (error: string) => void,
    ) => {
        socket?.emit(
            SESSION.JOIN,
            { joinCode, participantId },
            (socketResponse: SocketResponse) => {
                if (socketResponse.success) {
                    const { session } = socketResponse.data;

                    if (session) {
                        setSession(session);
                        onSuccess?.(session);
                    }
                }

                if (socketResponse.error) {
                    onError?.(socketResponse.error);
                }
            },
        );
    };

    return joinSession;
};

export default useJoinSession;
