import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { Topic } from '../../../../types/Topic.js';

export const useSendQuestions = () => {
    const sendQuestions = useMutation(
        async ({ emails, topic }: { emails: string[]; topic: Topic }) => {
            return API.post(apiName, '/topic/send-questions', {
                body: {
                    emails,
                    topic,
                },
            });
        },
    );

    return sendQuestions;
};
