import { useContext } from 'react';
import { SocketContext } from '../../context/Socket.js';
import { SESSION } from '../../../../constants/channels.js';
import { SocketResponse } from '../../../../types/Socket.js';

const useLeaveRoom = () => {
    const socket = useContext(SocketContext);

    const leaveRoom = (
        { joinCode, participantId }: { joinCode: string; participantId?: string },
        onSuccess?: () => void,
        onError?: (error: string) => void,
    ) => {
        socket?.emit(
            SESSION.LEAVE,
            { joinCode, participantId },
            (socketResponse: SocketResponse) => {
                if (socketResponse.success) {
                    onSuccess?.();
                }

                if (socketResponse.error) {
                    onError?.(socketResponse.error);
                }
            },
        );
    };

    return leaveRoom;
};

export default useLeaveRoom;
