import { API, Auth } from 'aws-amplify';
import { useMutation } from 'react-query';
import { captureException } from '@sentry/react';
import { apiName } from '../../../lib/amplify.js';
import { User } from '../../../../types/User.js';

export const useCreateStripeCheckoutSession = () => {
    const createStripCheckoutSession = useMutation(
        async ({ planType }: { planType }) => {
            const currentUser = (await Auth.currentAuthenticatedUser()) as User;

            return API.post(apiName, '/stripe/create-checkout-session', {
                body: {
                    email: currentUser?.attributes?.email,
                    planType,
                },
            });
        },
        {
            onSuccess: (data) => {
                const stripeUrl = data?.sessionUrl;

                if (stripeUrl) {
                    // redirect to stripe checkout
                    window.open(stripeUrl, '_blank');
                }
            },
            onError: (error) => {
                captureException(error);
            },
        },
    );

    return createStripCheckoutSession;
};
