import { useContext } from 'react';
import { captureException } from '@sentry/react';
import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { SetRoomContext } from '../../context/Room.js';

export const useDismissWelcomeModal = () => {
    const setRoom = useContext(SetRoomContext);

    const updateActivitySetting = useMutation(
        () => {
            return API.put(apiName, '/room/dismiss-welcome-modal', {});
        },
        {
            onSuccess: (data) => {
                // update the room context so we don't show the modal again
                setRoom(data.room);
            },
            onError: (error) => {
                captureException(error);
            },
        },
    );

    return updateActivitySetting;
};
