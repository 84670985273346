import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { apiName } from '../../../lib/amplify.js';
import { UserEditableRoom } from '../../../../types/Room.js';

export const useUpdateRoom = () => {
    const updateRoom = useMutation(({ room }: { room: UserEditableRoom }) => {
        return API.put(apiName, '/room/update', {
            body: {
                // These are currently the only fields the user can update
                state: room?.state,
                gradesTaught: room?.grades_taught,
                subjectsTaught: room?.subjects_taught,
                feedbackOpts: room?.feedback_opts,
                preferredActivityDuration: room?.preferred_activity_duration,
            },
        });
    });
    return updateRoom;
};
