import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import { captureException } from '@sentry/react';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Room } from '../../../../types/Room.js';
import { apiName } from '../../../lib/amplify.js';

export const useRoom = (onSuccess: ({ room }: { room?: Room }) => void) => {
    const getRoom = useQuery(
        QueryKeys.room,
        async () => {
            return API.get(apiName, '/room/', {});
        },
        {
            onSuccess: (data: { room: Room }) => {
                onSuccess?.(data);
            },
            onError: (error) => {
                captureException(error);
            },
        },
    );

    return getRoom;
};
