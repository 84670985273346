import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Participant } from '../../../types/Participant.js';
import { PARTICIPANT, SESSION } from '../../../constants/channels.js';
import {
    getDataFromSessionStorage,
    cacheDataInSessionStorage,
} from '../../lib/sessionStorage.js';
import { SocketContext } from './Socket.js';
import { SESSION_KEYS } from '../../../constants/sessionStorageKeys.js';
import { useParticipant } from '../hooks/queries/useParticipant.js';

export const ParticipantContext = createContext<Participant>(null);
export const SetParticipantContext =
    createContext<(participant: Participant) => void>(null);

export const ParticipantProvider = ({ children }: { children: JSX.Element }) => {
    const socket = useContext(SocketContext);
    const [participant, setParticipant] = useState<Participant>(null);

    const { data } = useParticipant({ participantId: participant?.id });

    // This is the participant that was queried from the server
    const queriedParticipant = data?.participant;

    const { joinCode } = useParams();

    const participantRef = useRef<Participant>(participant);

    const handleSetParticipantContext = useCallback((newParticipant: Participant) => {
        setParticipant(newParticipant);
        participantRef.current = newParticipant;
        cacheDataInSessionStorage(SESSION_KEYS.participant, newParticipant);
    }, []);

    useEffect(() => {
        if (queriedParticipant) {
            handleSetParticipantContext(queriedParticipant);
        }
    }, [queriedParticipant]);

    useEffect(() => {
        const persistedParticipant: Participant = getDataFromSessionStorage(
            SESSION_KEYS.participant,
        );
        participantRef.current = persistedParticipant;
        setParticipant(persistedParticipant);
    }, []);

    useEffect(() => {
        if (!socket || !joinCode) {
            return;
        }

        const handleUpdateBRTeam = (participants: Participant[]) => {
            participants.forEach((p) => {
                if (p.id === participantRef?.current?.id) {
                    handleSetParticipantContext(p);
                }
            });
        };

        const handleConnect = () => {
            if (joinCode) {
                // rejoin room
                socket?.emit(
                    SESSION.JOIN,
                    {
                        joinCode,
                        participantId: participant?.id,
                    },
                    () => null,
                );
            }
        };

        socket.on(PARTICIPANT.BR_TEAM_UPDATED, handleUpdateBRTeam);
        socket.on('connect', handleConnect);

        return () => {
            socket.off(PARTICIPANT.BR_TEAM_UPDATED, handleUpdateBRTeam);
            socket.off('connect', handleConnect);
        };
    }, [socket, joinCode, participant?.id]);

    return (
        <ParticipantContext.Provider value={participant}>
            <SetParticipantContext.Provider value={handleSetParticipantContext}>
                {children}
            </SetParticipantContext.Provider>
        </ParticipantContext.Provider>
    );
};
