import { useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useCurrentRoomActivityRound from '../socketEvents/useCurrentRoomActivityRound.js';
import { allInRoundMonitor } from './allInRoundMonitor.js';
import { pairItRoundMonitor } from './pairItRoundMonitor.js';
import { battleRoyaleRoundMonitor } from './battleRoyaleRoundMonitor.js';
import { quickWriteRoundMonitor } from './quickWriteRoundMonitor.js';
import { ParticipantContext, SetParticipantContext } from '../../context/Participant.js';
import { useAssignSingleBattleRoyaleTeam } from '../mutations/useAssignSingleBattleRoyaleTeam.js';
import { SessionContext } from '../../context/Session.js';
import { clearLocalSessionStorage } from '../../_helpers/clearLocalSessionStorage.js';

export const useActivityRoundMonitor = () => {
    const participant = useContext(ParticipantContext);
    const setParticipant = useContext(SetParticipantContext);
    const session = useContext(SessionContext);

    const { joinCode } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const currentSessionActivityRound = useCurrentRoomActivityRound();
    const useAssignSingleBattleRoyaleTeamMutation = useAssignSingleBattleRoyaleTeam();

    const activitySlug = session?.activity_slug;

    const joinNewSession = () => {
        if (session === null || session === undefined) {
            return;
        }

        // Clear session storage so student can start the next session with a clean slate
        clearLocalSessionStorage();

        navigate(`/join`, {
            replace: true,
        });
    };

    useEffect(() => {
        if (
            activitySlug !== 'battle-royale' ||
            !participant ||
            currentSessionActivityRound === 'question_round' ||
            currentSessionActivityRound === 'response_round' ||
            !currentSessionActivityRound
        ) {
            return;
        }

        const assignTeam = () => {
            if (participant?.team) {
                return Promise.resolve();
            }

            return new Promise((resolve, reject) => {
                useAssignSingleBattleRoyaleTeamMutation.mutate(
                    { participant, sessionId: session?.id },
                    {
                        onSuccess: (data) => {
                            setParticipant(data?.participant);
                            resolve(data);
                        },
                        onError: (error) => {
                            reject(error);
                        },
                    },
                );
            });
        };

        assignTeam();
    }, [currentSessionActivityRound, activitySlug, participant]);

    useEffect(() => {
        // Don't do anything until we have a session and a participant
        if (session === undefined || participant === undefined) return;

        /** Navigate to generic waiting room route if an activity_slug value isn't assigned to the question */
        if (!session?.is_active || !activitySlug) {
            joinNewSession();
            return;
        }

        if (location.pathname.includes('enter-name')) {
            // We don't want to redirect the user to any page when they are entering their name
            return;
        }

        switch (activitySlug) {
            case 'all-in':
                allInRoundMonitor({
                    joinCode,
                    currentSessionActivityRound,
                    navigate,
                });
                break;

            case 'pair-it':
                pairItRoundMonitor({
                    joinCode,
                    currentSessionActivityRound,
                    navigate,
                });
                break;

            case 'battle-royale':
                battleRoyaleRoundMonitor({
                    joinCode,
                    currentSessionActivityRound,
                    navigate,
                });

                break;

            case 'quick-write':
                quickWriteRoundMonitor({
                    joinCode,
                    currentSessionActivityRound,
                    navigate,
                });

                break;

            default:
                joinNewSession();
        }
    }, [currentSessionActivityRound, activitySlug, joinCode]);
};
