import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PARTICIPANT } from '../../../../constants/channels.js';
import { SocketContext } from '../../context/Socket.js';
import { ParticipantContext } from '../../context/Participant.js';
import { clearLocalSessionStorage } from '../../_helpers/clearLocalSessionStorage.js';

export const useRemovedParticipantMonitor = () => {
    const socket = useContext(SocketContext);
    const participant = useContext(ParticipantContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (socket) {
            socket.on(PARTICIPANT.REMOVED, (participantId: string) => {
                if (participantId === participant?.id) {
                    clearLocalSessionStorage();
                    navigate('/join', { replace: true });
                }
            });

            return () => {
                socket.off(PARTICIPANT.REMOVED);
            };
        }
    }, [socket, participant?.id]);
};
