import React, { createContext, useContext, useEffect } from 'react';
import { QUESTION, SESSION } from '../../../constants/channels.js';
import { Question } from '../../../types/Question.js';
import { SocketResponse } from '../../../types/Socket.js';
import { RoomContext } from './Room.js';
import { SocketContext } from './Socket.js';

export const QuestionContext = createContext<Question>(null);
export const SetQuestionContext = createContext<(question: Question) => void>(null);

export const QuestionProvider = ({ children }: { children: JSX.Element }) => {
    const room = useContext(RoomContext);
    const socket = useContext(SocketContext);
    const [question, setQuestion] = React.useState<Question>(null);

    const getActiveQuestion = () => {
        socket?.emit(QUESTION.GET_ACTIVE, { room }, (socketResponse: SocketResponse) => {
            if (socketResponse?.success) {
                setQuestion(socketResponse?.data?.activeQuestion);
            }
        });
    };

    useEffect(() => {
        if (socket && room) {
            getActiveQuestion();

            socket.on(SESSION.ADDED, () => {
                // If a session is added, the active question has changed
                getActiveQuestion();
            });

            socket.on(QUESTION.UPDATED, () => {
                getActiveQuestion();
            });

            return () => {
                socket.off(SESSION.ADDED);
                socket.off(QUESTION.UPDATED);
            };
        }
    }, [socket, room]);

    return (
        <QuestionContext.Provider value={question}>
            <SetQuestionContext.Provider value={setQuestion}>
                {children}
            </SetQuestionContext.Provider>
        </QuestionContext.Provider>
    );
};
