import { useContext } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants/queryKeys.js';
import { Participant } from '../../../../types/Participant.js';
import { SessionContext } from '../../context/Session.js';
import { ParticipantResponse } from '../../../../types/Response.js';

export const useParticipantResponse = ({ participant }: { participant: Participant }) => {
    const session = useContext(SessionContext);

    const sessionId = session?.id;

    const getParticipants = useQuery<{ response: ParticipantResponse[] }>(
        QueryKeys.participantResponse,
        async () => {
            const url = new URL(window.location.href);

            const response = await fetch(
                `${url.origin}/api/response/participant?sessionId=${sessionId}&participantId=${participant?.id}`,
            );

            return response.json();
        },
        { enabled: !!sessionId && !!participant?.id },
    );

    return getParticipants;
};
