import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SESSION } from '../../../../constants/channels.js';
import { ActivityRound } from '../../../../types/Room.js';
import { SocketContext } from '../../context/Socket.js';
import { SessionContext, SetSessionContext } from '../../context/Session.js';
import { Session } from '../../../../types/Session.js';

const useCurrentRoomActivityRound = () => {
    const socket = useContext(SocketContext);
    const session = useContext(SessionContext);
    const setSession = useContext(SetSessionContext);

    const { joinCode } = useParams();

    useEffect(() => {
        if (joinCode && socket) {
            socket.on(SESSION.UPDATED_ACTIVITY_ROUND, (activityRound: ActivityRound) => {
                setSession((prevSession: Session) => {
                    return {
                        ...prevSession,
                        current_activity_round: activityRound,
                    };
                });
            });

            return () => {
                socket.off(SESSION.UPDATED_ACTIVITY_ROUND);
            };
        }
    }, [socket, joinCode]);

    return session?.current_activity_round;
};

export default useCurrentRoomActivityRound;
