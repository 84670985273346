import React, { createContext, useContext, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { useParams } from 'react-router-dom';
import { SESSION } from '../../../constants/channels.js';
import { Session } from '../../../types/Session.js';
import { SocketResponse } from '../../../types/Socket.js';
import { SocketContext } from './Socket.js';
import { ParticipantContext } from './Participant.js';

export const SessionContext = createContext<Session>(null);
export const SetSessionContext =
    createContext<React.Dispatch<React.SetStateAction<Session>>>(null);

export const SessionProvider = ({ children }: { children: JSX.Element }) => {
    const socket = useContext(SocketContext);
    const participant = useContext(ParticipantContext);

    const { joinCode } = useParams();

    const [session, setSession] = useState<Session>(null);

    const joinSession = () => {
        socket.emit(
            SESSION.JOIN,
            { joinCode, participantId: participant?.id },
            (joinSocketResponse: SocketResponse) => {
                if (joinSocketResponse.error) {
                    if (participant?.id) {
                        captureException(joinSocketResponse?.error);
                    }
                }
            },
        );
    };

    const getActiveSession = () => {
        socket?.emit(
            SESSION.GET_ACTIVE,
            { joinCode },
            (socketResponse: SocketResponse) => {
                if (socketResponse?.success) {
                    const { activeSession } = socketResponse.data ?? {};

                    setSession(activeSession);

                    if (activeSession) {
                        joinSession();
                    }
                }

                if (socketResponse?.error) {
                    captureException(socketResponse?.error);
                }
            },
        );
    };

    useEffect(() => {
        if (socket && joinCode) {
            getActiveSession();

            socket.on(SESSION.ADDED, () => {
                // If a session is added, the active session has changed
                getActiveSession();
            });

            socket.on(SESSION.ENDED, () => {
                getActiveSession();
            });

            socket.on('connect', () => {
                joinSession();
            });

            return () => {
                socket.off(SESSION.ADDED);
                socket.off(SESSION.ENDED);
                socket.off('connect');
            };
        }
    }, [socket, joinCode]);

    return (
        <SessionContext.Provider value={session}>
            <SetSessionContext.Provider value={setSession}>
                {children}
            </SetSessionContext.Provider>
        </SessionContext.Provider>
    );
};
